/*
 * This stylesheet generated by the CSS3 @font-face generator v2.0
 * by Zoltan Hawryluk (http://www.useragentman.com).
 * Latest version of this program is available at
 * https://github.com/zoltan-dulac/css3FontConverter
 */


@font-face {
  font-family: 'GretaArabic';
  font-weight: bold;
  src: url('../fonts/greta/Greta_Arabic_Bold.eot?') format('eot'),
       url('../fonts/greta/Greta_Arabic_Bold.woff2') format('woff2'),
       url('../fonts/greta/Greta_Arabic_Bold.woff') format('woff'),
       url('../fonts/greta/Greta_Arabic_Bold.otf')  format('opentype'),
       url('../fonts/greta/Greta_Arabic_Bold.ttf')  format('truetype'),
       url('../fonts/greta/Greta_Arabic_Bold.svg#Greta_Arabic_Bold') format('svg');
}

@font-face {
  font-family: 'GretaArabic';
  font-weight:normal;
  src: url('../fonts/greta/Greta_Arabic_Regular.eot?') format('eot'),
       url('../fonts/greta/Greta_Arabic_Regular.woff2') format('woff2'),
       url('../fonts/greta/Greta_Arabic_Regular.woff') format('woff'),
       url('../fonts/greta/Greta_Arabic_Regular.otf')  format('opentype'),
       url('../fonts/greta/Greta_Arabic_Regular.ttf')  format('truetype'),
       url('../fonts/greta/Greta_Arabic_Regular.svg#Greta_Arabic_Regular') format('svg');
}
