$font-path: "fonts";
$font-name: "FontAwesome";
$font-file-name: "flexslider-icon";
$default-duration: 1s;
$default-easing: ease;
$default-border-radius: 0;
$default-box-shadow-x-axis: 0;
$default-box-shadow-y-axis: 0;
$default-box-shadow-blur: 0;
$default-box-shadow-color: transparent;
$default-box-shadow-inset: null;
$flexslider-margin: 0 40px 60px;
$flexslider-bg: $body-background;
$flexslider-border: none;
$flexslider-border-radius: 0;
$flexslider-box-shadow-x-axis: 0;
$flexslider-box-shadow-y-axis: 0;
$flexslider-box-shadow-blur: 0;
$flexslider-box-shadow-color: transparent;
$flexslider-box-shadow-inset: null;
$flex-viewport-max-height: 2000px;
$flex-viewport-loading-max-height: 300px;
$flex-control-thumbs: 5px 0 0;
$flex-direction-nav-color: $primary-color;
$flex-direction-nav-text-shadow: 1px 1px 0 rgba( 255, 255, 255, 0.3);
$flex-direction-nav-icon-color: $primary-color;
$flex-direction-nav-icon-text-shadow: 1px 1px 0 rgba( 255, 255, 255, 0.3);
$flex-direction-nav-icon-prev: $fa-var-caret-right;
$flex-direction-nav-icon-next: $fa-var-caret-left;
@if $opp-float=='right' {
  $flex-direction-nav-icon-prev: $fa-var-caret-left;
  $flex-direction-nav-icon-next: $fa-var-caret-right;
}

$flex-pauseplay-icon-play: $fa-var-play;
$flex-pauseplay-icon-pause: $fa-var-pause;

/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/

.flex-container,
.flex-slider {
  a:hover {
    outline: none;
  }
}

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-pauseplay span {
  text-transform: capitalize;
}


/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/

.flexslider {
  margin: 0;
  padding: 0;
  .slides {
    transform-origin: $float top;
    > li {
      display: none;
      -webkit-backface-visibility: hidden;
    }
    img {
      width: 100%;
      display: block;
    }
    //
    // Clearfix for the .slides element
    //
    &:after {
      content: "\0020";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
    html[xmlns] & {
      display: block;
    }
    * html & {
      height: 1%;
    }
    //
    // No JavaScript Fallback
    // If you are not using another script, such as Modernizr, make sure you
    // include js that eliminates this class on page load
    //
    .no-js & {
      > li {
        &:first-child {
          display: block;
        }
      }
    }
  }
}


/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/

.flexslider {
  margin: $flexslider-margin;
  background: $flexslider-bg;
  border: $flexslider-border;
  position: relative;
  //zoom: 1;
  border-radius: $flexslider-border-radius;
  box-shadow: $flexslider-box-shadow-x-axis $flexslider-box-shadow-y-axis $flexslider-box-shadow-blur $flexslider-box-shadow-color $flexslider-box-shadow-inset;
  .slides {
    //zoom: 1;
    img {
      height: auto;
      -moz-user-select: none;
    }
  }
}

.flex-viewport {
  max-height: $flex-viewport-max-height;
  transition: all $default-duration $default-easing;
  .loading & {
    max-height: $flex-viewport-loading-max-height;
  }
}

.carousel li {
  margin-#{$opp-float}: 5px;
}

//
// Direction Nav
//
.flex-direction-nav {
  *height: 0;
  //
  // Global styles
  //
  a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: $flex-direction-nav-color;
    text-shadow: $flex-direction-nav-text-shadow;
    transition: all 0.3s, ease-in-out;
    &:before {
      @include fa-icon;
      font-family: $font-name;
      font-size: 40px;
      display: inline-block;
      content: $flex-direction-nav-icon-prev;
      color: $flex-direction-nav-icon-color;
      text-shadow: $flex-direction-nav-icon-text-shadow;
    }
    &.flex-next:before {
      content: $flex-direction-nav-icon-next;
    }
  }
  //
  // Prev
  //
  .flex-prev {
    #{$float}: -40px;
    opacity: 0.7;

  }
  //
  // Next
  //
  .flex-next {
    #{$opp-float}: -40px;
    opacity: 0.7;

    text-align: $opp-float;
  }
  //
  // Hover states
  //
  .flexslider:hover & {
    .flex-prev {
      opacity: 0.7;
      //#{$float}: 10px;
      &:hover {
        opacity: 1;
      }
    }
    .flex-next {
      opacity: 0.7;
      //#{$opp-float}: 10px;
      &:hover {
        opacity: 1;
      }
    }
  }
  //
  // Disabled
  //
  .flex-disabled {
    color: rgba($body-font-color,.25);
    &:before{
      color:rgba($body-font-color,.5);
    }
    cursor: default;
    z-index: -1;
  }
}

//
// Pause/Play
//
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  #{$float}: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
  &:before {
    @include fa-icon;
    font-family: $font-name;
    font-size: 20px;
    display: inline-block;
    content: $flex-pauseplay-icon-pause;
  }
  &:hover {
    opacity: 1;
  }
  &.flex-play:before {
    content: $flex-pauseplay-icon-play;
  }
}

//
// Control Nav
//
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
  li {
    margin: 0 6px;
    display: inline-block;
    //zoom: 1;
    *display: inline;
  }
}

//
// Control paging
//
.flex-control-paging li {
  a {
    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-indent: -9999px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset;
    border-radius: 20px;
    &:hover {
      background: $primary-color;
      background: rgba($primary-color, 0.7);
    }
    &.flex-active {
      background: $primary-color;
      background: rgba( $primary-color, 0.9);
      cursor: default;
    }
  }
}

.flex-control-thumbs {
  margin: $flex-control-thumbs;
  position: static;
  overflow: hidden;
  li {
    width: 25%;
    float: $float;
    margin: 0;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    opacity: .7;
    cursor: pointer;
    -moz-user-select: none;
    transition: all $default-duration $default-easing;
    &:hover {
      opacity: 1;
    }
  }
  .flex-active {
    opacity: 1;
    cursor: default;
  }
}

