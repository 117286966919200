.l-header {
  $header-height: 100px;
  padding:.5rem 0;
  border-bottom:1px solid $body-font-color;
  .row{
    position: relative;
  }
  .logo{
    line-heigh:1;
    font-size:1em;
    margin:0;

    a{
      img{
        width:auto;
        height:$header-height;
      }
    }
  }
  .sitename{
    margin:0;
    padding:0;
    img{
      height:$header-height;
    }
  }
  .lang{
    position: absolute;
    bottom:0;
    #{$opp-float}: 0;
    li{
      float:$opp-float;
      a{
        color:$body-font-color;
        padding-bottom:0;
        &.active{
          color:$primary-color;
        }
      }
    }
  }
}
