#interactive-map {
  //overflow: hidden;
  position: relative;
  transition: all .2s ease;
  overflow: hidden;
  &.loading {
    overflow: hidden;
    max-height: 400px!important;
    .canvas,.layer{
      opacity:0;
    }
  }
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background: $black;
    img {
      transition: all .3s linear;
      transform-origin: $float top;
      max-width: none;
      position: relative;
      transform: scale(0.5);
      opacity: 0;
      z-index: 0;
      position: absolute;
      top: 0;
      #{$float}: 0;
      &.active {
        z-index: 1;
        opacity: 1;
      }
    }
  }
  &.in-view {
    //overflow: visible;
    //height:auto!important;
    .canvas {
      position: fixed;
    }
  }
  &.out-down {
    .canvas {
      bottom: 0;
      top: auto;
    }
  }
  &.out-up {
    .canvas {
      top: 0;
      bottom: auto;
    }
  }
  .layer {
    position: relative;
    //outline: 1px solid red;
    z-index: 3;
    &.empty {
      .box {
        display: none;
      }
    }
    .box {
      p {
        background: $body-background;
        padding: 1em;
        box-shadow: 0 0 .5em $body-font-color;
      }
      color:$body-font-color;
      position: absolute;
      left: 100px;
      top:50%;
      margin-top:-200px;
      width:320px;
      height:480px;
      z-index:3;
    }
    .content {
      transition: opacity .3s linear;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      z-index: 2;
      .label {
        position: absolute;
        z-index: 2;
      }
      .img {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        img {
          transform-origin: $float top;
          max-width: none;
          position: absolute;
          top: 0;
          right: 0;
          transform: scale(1) translateX(1%) translateY(-20%);
          opacity:.6;
        }
      }
    }
  }
  &.in-view {
    .layer {
      &.l-in-view {
        .content {
          opacity: 1;
          position: fixed;
        }
      }
    }
  }
}

.loader-wrap {
  display: none;
  @include loader($white);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $black;
  z-index: 1;
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
.loading{
  .loader-wrap{
    display: block;
  }
}
