.l-footer {
  padding:1em 0;
  color: $body-background;
  background-color: $body-font-color;

  a{
    color:inherit;
  }
  .menu{
    margin:0 0 1em;
    display:table;
    width:100%;
    text-align:center;
    @include breakpoint(medium){
        display:block;
    }
    li{
      font-size:1.1em;
    }
  }
  .social{
        max-width: 128px;

    li{
      text-align:center;
    }
    .fa{
      font-size:2em;
    }
  }
  .logos{
    a{
        display: block;
        margin-bottom:.5em;
        img{
          height:128px;
          width:auto;
        }
    }
    text-align:center;
    @include breakpoint(medium){
      text-align:$opp-float;
      a{

      }
    }
  }
}
