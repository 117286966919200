body{
  overflow-x:hidden;
}
.l-main {
  .row {}
  .hero {
    text-align:center;
     padding: map-get($grid-column-gutter, medium)*1 0;
    @include breakpoint(medium){
      padding: map-get($grid-column-gutter, medium)*2 0;
    }

    font-family: $header-font-family;
    .columns{
      @include breakpoint(medium){
        //@include grid-col(8);
        //@include grid-col-off(4)

      }
    }
    .title {
      font-size:1.5em;
      @include breakpoint(medium){
        font-size: 3em;
      }
    }
    .body {
      font-size:1.2em;
      @include breakpoint(medium){
        font-size: 1.5em;
      }
    }


  }

}
