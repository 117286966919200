.node {
  margin:1em 0;
  &.text {
    .node-content {
      @include breakpoint(medium) {
        width: 60%;
        margin:0 auto;

      }
      p{

        .img{
          float:$float;
          margin-bottom:0;
          margin-#{$opp-float}: 1em;
          @include breakpoint(medium) {
            max-width:25%;
          }
        }
      }
    }
  }
  &.image{
    overflow: hidden;
    .node-content{
      img{
        max-width:100%; height:auto;
        min-width:100%;
      }
    }
  }
}
