.flexslider{
&.historic-maps {
  //float: $float;

  .flex-control-nav {}
  .flex-direction-nav {}
  .slides {

    li{

    }
    .slide {

      position: relative;
      float: $float;
      .caption {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: 1em;
        color: $body-background;
        background: rgba($body-font-color, .6);
      }
    }
  }
}

}
